import React from "react";
import styled from "styled-components";

import Img from "../../imgs/Group 7.svg";
import Img1 from "../../imgs/favourite-bold.svg";
import Img2 from "../../imgs/book-bold.svg";
import Img3 from "../../imgs/internet-bold.svg";
import { Container, Background } from "../Container";

const Part1 = () => {
  return (
    <Background backgroundColor="#ecf0f3">
      <Container id="about">
        <Row paddingTop="160px">
          <Left>
            <SectionName>ABOUT US</SectionName>
            <Title>We're Studio Box</Title>
            <Text1>
              Studio Box is a digital agency based in San Juan, Argentina. We
              develop projects in which innovation, communication and user
              experience are key to transform our customer's ideas into
              high-quality products.
            </Text1>
            <br />
            <Text1>
              We research, think and create solutions that help companies reach
              new users, offer new products and open new markets.
            </Text1>
          </Left>
          <Illustration src={Img} alt="Illustration" />
        </Row>
        <Subtitle>What defines us</Subtitle>
        <Row2>
        <SubtitleMobile>What defines us</SubtitleMobile>
          <Box>
            <Illustration2 src={Img1} alt="Illustration" />
            <Subtitle2>Passionates</Subtitle2>
            <Text2>
              We really love what we do, that's the main reason we put our best
              in every project, working without rest to reach excellence.
            </Text2>
          </Box>
          <Box>
            <Illustration2 src={Img2} alt="Illustration" />
            <Subtitle2>Learners</Subtitle2>
            <Text2>
              We're committed to continue growing and improving our knowledge
              about the digital world to help others with the latest technology
              available.
            </Text2>
          </Box>
          <Box marginLeft="0px">
            <Illustration2 src={Img3} alt="Illustration" />
            <Subtitle2>Limitless</Subtitle2>
            <Text2>
              There aren't geographical barriers that prevent us from reaching
              clients. That has led us to work remotely for 4+ years with
              companies from other countries.
            </Text2>
          </Box>
        </Row2>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${props => props.paddingTop || "0px"};
  align-items: center;

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    flex-direction: column;
  }
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${props => props.paddingTop || "0px"};

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    flex-direction: column;
    padding-bottom: 120px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;

const Illustration = styled.img`
  width: 320px;
  height: 214px;
  margin-left: 48px;
  flex-shrink: 0;

  @media (min-width: 1024px) {
    margin-left: 56px;
  }

  @media (min-width: 1150px) {
    width: 400px;
    height: 320px;
    margin-left: 120px;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 48px);
    margin-left: 24px;
    margin-top: 64px;
  }
`;

const Illustration2 = styled.img`
  width: 32px;
  height: 32px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const Box = styled.div`
  max-width: 271px;
  border-radius: 14px;
  background-color: #ecf0f3;
  box-shadow: -10px -10px 20px 0 #ffffff, 10px 10px 20px 0 #d1d9e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${props => props.marginLeft || "40px"};
  margin-top: 40px;
  margin-bottom: 110px;
  padding: 24px;

  @media (max-width: 768px) {
    width: calc(100vw - 96px);
    padding: 24px;
    margin-left: 24px;
    margin-top: 24px;
  margin-bottom: 24px;
  }
`;

const SectionName = styled.h3`
  color: #728695;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px;
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 500;
  line-height: 62px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    font-size: 48px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  }
`;

const Text1 = styled.p`
  color: #728695;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 28px;
  margin: 0px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

const Subtitle = styled.h2`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-top: 100px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const SubtitleMobile = styled.h2`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-top: 100px; 

  @media (min-width: 769px) {
    display: none;
  }
`;

const Subtitle2 = styled.h2`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-bottom: 24px;
`;

const Text2 = styled.p`
  color: #47667e;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0px;
`;

export default Part1;
