import React from "react";
import styled from "styled-components";

import Footer from "../Home/Footer";
import Lottie from "react-lottie";
import animation from "../Animations/animation.json";
import { Container, Background } from "../Container";

const Contact = () => {
  return (
    <>
      <Background backgroundColor="#ecf0f3">
        <Container style={{ minHeight: "calc(100vh - 300px" }}>
          <Row>
            <Left>
              <Part>CONTACT</Part>
              <Title>Let's work together</Title>
              <Text>
                We partner with companies to craft digital experiences that
                matter. For general inquiries reach us here:{" "}
                <Mail href="mailto:studioboxsanjuan@gmail.com?subject=Contact%20from%20the%20website">
                  studioboxsanjuan@gmail.com
                </Mail>
              </Text>
            </Left>
            <AnimationDesktop>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                height={302}
                width={360}
                style={{
                  paddingTop: 97,
                  marginBottom: 100,
                  margin: 0,
                  marginLeft: 105
                }}
              />
            </AnimationDesktop>
            <AnimationTablet>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                height={302}
                width={360}
                style={{ marginBottom: 50, margin: 0 }}
              />
            </AnimationTablet>
          </Row>
        </Container>
      </Background>
      <Footer />
    </>
  );
};

const Row = styled.div`
  flex-grow: 1;
  padding-top: 150px;
  background-color: #ecf0f3;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    flex-direction: column;
  }
`;

const Left = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: column;
`;

const Part = styled.h2`
  color: #728695;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px;
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 500;
  line-height: 62px;
  margin: 0px;
  margin-top: 14px;
  margin-bottom: 18px;

  @media (min-width: 1024px) {
    font-size: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  }
`;

const Text = styled.p`
  text-justify: auto;
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
`;

const Mail = styled.a`
  cursor: pointer;
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin: 0px;
  display: inline-block;
  margin-left: 0px;
  text-decoration: none;
`;

const AnimationDesktop = styled.div`
  @media (max-width: 1149px) {
    display: none;
  }
`;

const AnimationTablet = styled.div`
  @media (min-width: 1150px) {
    display: none;
  }
`;

export default Contact;
