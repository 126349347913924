import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Img from "../../imgs/People Carrying Browser Tab.svg";
import { Container, Background } from "../Container";

const Part5 = () => {
  return (
    <Background backgroundColor="white">
      <Container>
        <Row>
          <Left>
            <Title>Want to start working on a new project?</Title>
            <Text>
              We're always up for a fresh challenge.
              <br />
              To begin the process please provide us some overview information
              about your project. It will take you a minute.
            </Text>
            <Button to="/contact">GET IN TOUCH</Button>
          </Left>
          <Illustration src={Img} alt="Illsutration" />
        </Row>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
  }
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-bottom: 24px;
`;

const Text = styled.p`
  color: #728695;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 28px;
  margin: 0px;
  margin-bottom: 85px;

  @media (min-width: 1150px) {
    margin-bottom: 100px;
  }
`;

const Button = styled(Link)`
  box-sizing: border-box;
  height: 46px;
  width: 162px;
  border: 2px solid #1c4a6e;
  border-radius: 25px;
  background-color: #1c4a6e;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Illustration = styled.img`
  width: 266px;
  height: 280px;
  margin-left: 100px;
  flex-shrink: 0;

  @media (min-width: 1150px) {
    width: 270px;
    height: 290px;
    margin-left: 175px;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    margin-top: 64px;
  }
`;

export default Part5;
