import React from "react";
import styled from "styled-components";

import Img from "../../imgs/User Interaction.svg";
import { Container, Background } from "../Container";

const Part2 = () => {
  return (
    <Background backgroundColor="white">
      <Container>
        <Row>
          <Left>
            <Title>
              We're a multidisciplinary team built on our core values of
              honesty, hard work, and trust.
            </Title>
            <Text>
              Our goal is always to grow as a company and provide the best
              services to our customers. That is why we always try to do our
              best in every project, always seeking customers satisfaction and
              personal progress.
            </Text>
          </Left>
          <Illustration src={Img} alt="Illustration" />
        </Row>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 110px;
  padding-bottom: 110px;

  @media (max-width: 768px) {
    max-width: calc(100vw - 48px);
    margin-left: 24px;
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
`;

const Text = styled.p`
  color: #728695;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 28px;
`;

const Illustration = styled.img`
  width: 320px;
  height: 214px;
  margin-left: 50px;
  flex-shrink: 0;

  @media (min-width: 1024px) {
    margin-left: 90px;
  }

  @media (min-width: 1150px) {
    width: 400px;
    height: 320px;
    margin-left: 120px;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    margin-top: 64px;
  }
`;

export default Part2;
