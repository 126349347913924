import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./index.css";
import { scroller } from "react-scroll";

import Navbar from "./components/Home/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import FullScreenMenu from "./components/FullScreenMenu";
import styled from "styled-components";

const ScrollTo = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const MegaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function App() {
  const location = useLocation();
  useEffect(() => {
    const id = location.hash.slice(1);
    if (id !== "") {
      scroller.scrollTo(id, { smooth: true, duration: 1000 });
    }
  }, [location]);

  return (
    <MegaContainer>
      <ScrollTo />
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/menu">
          <FullScreenMenu />
        </Route>
      </Switch>
    </MegaContainer>
  );
}

export default App;
