import React from "react";
import styled from "styled-components";

import Img1 from "../../imgs/Inv-mark@2x.svg";
import Img2 from "../../imgs/Group 33.svg";
import Img3 from "../../imgs/img1.svg";
import Img4 from "../../imgs/img2.svg";
import Img5 from "../../imgs/img3.svg";
import Img6 from "../../imgs/img4.svg";
import Img7 from "../../imgs/img5.svg";
import Img8 from "../../imgs/img6.png";
import { Container, Background } from "../Container";

const Part4 = () => {
  return (
    <Background backgroundColor="#ecf0f3">
      <Container id="clients">
        <Row1>
          <Part>OUR CLIENTS</Part>
          <Title>From startups to established companies</Title>
          <Text1>
            We've had the pleasure of working for clients from different
            <br />
            countries. Here is a small slecetion, past and present
          </Text1>
        </Row1>
        <Row>
          <Box>
            <Illustration
              style={{ width: 70, height: 70 }}
              src={Img1}
              alt="Illustration"
            />
            <TitleBox>Invisibly</TitleBox>
            <Subtitle>Clayton, MO, United States</Subtitle>
            <Text2>
              UI/UX Design, Website Design, Illustrations,
              <br /> Front-End Development
            </Text2>
          </Box>
          <Box>
            <Illustration src={Img2} alt="Illustration" />
            <TitleBox>Off The Grid</TitleBox>
            <Subtitle>San Francisco, CA, United States</Subtitle>
            <Text2>
              UI/UX Design, Website Design, Mobile App <br />
              Design, Prototyping and Iteractions, Front- <br />
              End Development
            </Text2>
          </Box>
          <Box2>
            <Illustration src={Img3} alt="Illustration" />
            <Illustration src={Img4} alt="Illustration" />
            <Illustration src={Img5} alt="Illustration" />
            <Illustration src={Img6} alt="Illustration" />
            <Illustration src={Img7} alt="Illustration" />
            <Illustration2 src={Img8} alt="Illustration" />
          </Box2>
          <Box2Mobile>
            <BoxRow>
              <Illustration src={Img3} alt="Illustration" />
            <Illustration src={Img4} alt="Illustration" />
            </BoxRow>
            <BoxRow>
            <Illustration src={Img5} alt="Illustration" />
            <Illustration src={Img6} alt="Illustration" />
            </BoxRow>
            <BoxRow>
            <Illustration src={Img7} alt="Illustration" />
            <Illustration2 src={Img8} alt="Illustration" />
            </BoxRow>
          
          </Box2Mobile>
        </Row>
      </Container>
    </Background>
  );
};

const Row1 = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-top: 80px;

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  justify-content: space-between;
  }
`;

const Box = styled.div`
  max-width: 360px;
  border-radius: 14px;
  background-color: #ecf0f3;
  box-shadow: -10px -10px 20px 0 #ffffff, 10px 10px 20px 0 #d1d9e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: 48px;

  @media (min-width: 1024px) {
    max-width: 432px;
  }

  @media (min-width: 1150px) {
    max-width: 488px;
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 48px);
    margin-left: 24px;
  }
`;

const Box2 = styled.div`
  min-height: 160px;
  margin-top: 48px;
  margin-bottom: 80px;
  border-radius: 14px;
  background-color: #ecf0f3;
  box-shadow: -10px -10px 20px 0 #ffffff, 10px 10px 20px 0 #d1d9e6;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  @media (min-width: 1150px) {
    margin-top: 64px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
const Box2Mobile = styled.div`
  max-width: calc(100vw - 48px);
  margin-left: 24px;
  min-height: 160px;
  margin-top: 48px;
  margin-bottom: 80px;
  border-radius: 14px;
  background-color: #ecf0f3;
  box-shadow: -10px -10px 20px 0 #ffffff, 10px 10px 20px 0 #d1d9e6;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  @media (min-width: 769px) {
    display: none
  }
`;

const BoxRow = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const Part = styled.h2`
  color: #728695;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-bottom: 24px;
`;

const TitleBox = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 32px;
`;

const Subtitle = styled.h2`
  color: #e57272;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
`;

const Text1 = styled.p`
  color: #728695;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 28px;
  margin: 0px;
`;

const Text2 = styled.p`
  color: #47667e;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0px;
`;

const Illustration = styled.img`
  height: 100px;
  width: 90px;
`;

const Illustration2 = styled.img`
  height: 100px;
  width: 120px;
`;

export default Part4;
