import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Img from "../../imgs/Group 6.svg";
import { Container, Background } from "../Container";

const Part1 = () => {
  return (
    <Background backgroundColor="#ecf0f3">
      <Container id="logo">
        <BoxContainer>
          <Left>
            <Text>
              We help companies bring <br />
              digital experiences to life... <br />
              Thinking out of the box
            </Text>
            <TextMobile>
              We help companies bring
              digital experiences to life... <br />
              Thinking out of the box
            </TextMobile>
            <Button to="/contact">GET IN TOUCH</Button>
          </Left>
          <Illustration src={Img} alt="Illustration" />
        </BoxContainer>
      </Container>
      <Polygon />
    </Background>
  );
};

const BoxContainer = styled.div`
  padding-top: 140px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1150px) {
    width: 700px;
  }
`;

const Polygon = styled.div`
  @media (min-width: 1150px) {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    clip-path: polygon(0% 100%, 100% 75%, 100% 100%);
  }
`;

const Text = styled.p`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 50px;
  margin: 0px;
  margin-bottom: 64px;
  margin-right: 60px;

  @media (min-width: 1024px) {
    font-size: 40px;
    margin-right: 90px;
    line-height: 60px;
  }

  @media (min-width: 1150px) {
    font-size: 42px;
    margin-right: 120px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const TextMobile = styled.p`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  margin: 0px;
  margin-bottom: 64px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Button = styled(Link)`
  box-sizing: border-box;
  height: 42px;
  width: 158px;
  border: 2px solid #1c4a6e;
  border-radius: 25px;
  background-color: #1c4a6e;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1150px) {
    width: 162px;
    height: 46px;
  }

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Illustration = styled.img`
  width: 240px;
  height: 300px;
  z-index: 10;
  flex-shrink: 0;

  @media (min-width: 1150px) {
    width: 300px;
    height: 360px;
    margin-right: 0px;
  }

  @media (max-width: 768px) {
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Part1;
