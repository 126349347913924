import styled from "styled-components";

export const Container = styled.div`
  max-width: 764px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 1024px) {
    max-width: 896px;
  }

  @media (min-width: 1150px) {
    max-width: 1040px;
  }
`;

export const Background = styled.div`
  background-color: ${props => props.backgroundColor || "#1c4a6e"};
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;
