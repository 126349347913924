import React from "react";

import Part1 from "./Part1";
import Part2 from "./Part2";
import Footer from "../Home/Footer";

const About = () => {
  return (
    <>
      <>
        <Part1 />
        <Part2 />
      </>
      <Footer />
    </>
  );
};

export default About;
