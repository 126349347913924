import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, Background } from "./Container";

const FullScreenMenu = () => {
  return (
    <>
      <Background backgroundColor="#1C4A6E">
        <Container style={{ display: "inline-block", minHeight: "calc(100vh - 160px)", paddingTop: 50 }}>
        <Bar>
            <Nav to="/#services">Services</Nav>
            <Nav to="/#clients">Clients</Nav>
            <Nav to="/about#about">About</Nav>
            <Nav to="/contact">Contact</Nav>
          </Bar>
        </Container>
      </Background>
    </>
  );
};

const Bar = styled.ul`
width: 100%;
height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: right;
  margin: 0px;
`;

const Nav = styled(Link)`
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 32px;
  text-decoration: none;
`;

export default FullScreenMenu;