import React from "react";
import styled from "styled-components";

import Img from "../../imgs/Icon Library.svg";
import { Container, Background } from "../Container";

const Part3 = () => {
  return (
    <Background backgroundColor="#1c4a6e">
      <Container>
        <Row>
          <Box>
            <Text>
              <Title>We focus on what we do best.</Title>
              <List>Illustrations & Iconography</List>
              <List>UI/UX Design</List>
              <List>Mobile App Design</List>
              <List>Website Design</List>
              <List>Prototyping and Interactions</List>
              <List>Front-End Development</List>
              <List>Back-End Development</List>
            </Text>
          </Box>
          <Illustration src={Img} alt="Illustration" />
        </Row>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 100px;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Box = styled.div`
  height: 380px;
  width: 425px;
  border-radius: 14px;
  background-color: #1c4a6e;
  box-shadow: -10px -10px 20px 0 #245e8c, 10px 10px 20px 0 #163e5d;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1150px) {
    height: 425px;
    width: 500px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Text = styled.div`
  color: #ffffff;
  font-family: Montserrat;
  padding-left: 34px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
`;

const List = styled.li`
  font-size: 16px;
  line-height: 28px;
`;

const Illustration = styled.img`
  height: 316px;
  width: 280px;

  @media (max-width: 900px) {
    margin-top: 80px;
  }

  @media (min-width: 1150px) {
    height: 425px;
    width: 377px;
  }
`;

export default Part3;
