import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo1 from "../../imgs/sbx1.svg";
import { Container } from "../Container";
import MenuIcon from "../../imgs/menu-icon.svg"

const useWindowScroll = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const listener = () => {
      setScroll(window.scrollY);
    };

    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);
  return scroll;
};

const Navbar = () => {
  const scroll = useWindowScroll();

  return (
    <NavbarContainer shadow={scroll > 50}>
      <Container>
        <Row>
          <NavLogo to="/#logo">
            <Logo src={Logo1} alt="Logo" />
          </NavLogo>
          <Bar>
            <Nav to="/#services">Services</Nav>
            <Nav to="/#clients">Clients</Nav>
            <Nav to="/about#about">About</Nav>
            <Nav to="/contact">Contact</Nav>
          </Bar>
        </Row>
        <RowMobile>
        <NavLogo to="/#logo">
            <Logo src={Logo1} alt="Logo" />
          </NavLogo>
          <MenuButton>
          <Nav to="/menu">
            <MenuIconImg src={MenuIcon} alt="Menu Icon" />
            </Nav>
          </MenuButton>
        </RowMobile>
      </Container>
    </NavbarContainer>
  );
};

const NavbarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background-color: #ecf0f3;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => (props.shadow ? "1px 2px 4px #a3a3a3d3" : "")};
  z-index: 2000;

  @media (min-width: 1150px) {
    height: 65px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;
const RowMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: 60px;
`;

const Bar = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: right;
  margin: 0px;
`;

const Nav = styled(Link)`
  color: #1c4a6e;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  margin-left: 30px;

  @media (min-width: 1150px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const NavLogo = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;

  @media (min-width: 1150px) {
    margin-right: 500px;
  }
  @media (max-width: 768px) {
    margin-left: 24px;
  }
`;

const MenuButton = styled.div`
width: 64px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
background-color: #D1D9E6;
border-radius: 8px;
margin-right: 24px;
`

const MenuIconImg = styled.img`
width: 24px;
height: 17px;
margin: 0px;
`

export default Navbar;
