import React from "react";
import styled from "styled-components";

import Img from "../../imgs/drawkit-mobile-article-monochrome.svg";
import Img1 from "../../imgs/Group 30.svg";
import { Container, Background } from "../Container";

const Footer = () => {
  return (
    <Background>
      <Container>
        <Row>
          <img src={Img} alt="Illustration" />
          <Right>
            <Logo src={Img1} alt="Logo" />
            <LinksRow>
              <Links
                href="https://www.linkedin.com/company/28143448"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINKEDIN
              </Links>
              <Links
                href="https://www.instagram.com/studiobox.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                INSTAGRAM
              </Links>
            </LinksRow>
            <div style={{ flexGrow: 1 }} />
            <CR>© Copyright 2020 Studio Box. All rights reserved.</CR>
          </Right>
        </Row>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  background-color: #1c4a6e;
  flex-grow: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
`;

const LinksRow = styled.div`
  margin-top: 24px;
  margin-left: 89px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
`;

const Logo = styled.img`
  height: 30px;
  width: 201px;
  padding-top: 34px;
  margin-left: 89px;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Links = styled.a`
  text-decoration: none;
  color: #ecf0f3;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 8px;
    margin-right: 8px;
  }
  
`;

const CR = styled.p`
  color: #d1d9e6;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  margin: 0px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export default Footer;
