import React from "react";
import styled from "styled-components";

import Img from "../../imgs/Solving Problems (male and female).svg";
import { Container, Background } from "../Container";

const Part2 = () => {
  return (
    <Background backgroundColor="white">
      <Container id="services">
        <Row>
          <Illustration src={Img} alt="Illustration" />
          <Text>
            <Part>OUR SERVICES</Part>
            <Title>We build digital products.</Title>
            <Parragraph marginBottom="48px">
              Our job is to make digital products that are functional and
              beautiful. We've spent a lot of time learning and working side by
              side with many different companies around the world. It doesn't
              matter what your company does, we can help you.
            </Parragraph>
            <Title>Ok, but what do we do?</Title>
            <Parragraph>
              We make digital products that connects your company with real
              users, delivering great quality through strategy, desing and
              development.
            </Parragraph>
          </Text>
        </Row>
      </Container>
    </Background>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const Illustration = styled.img`
  width: 340px;
  height: 256px;
  margin-right: 90px;
  flex-shrink: 0;

  @media (min-width: 1150px) {
    width: 400px;
    height: 316px;
    margin-right: 110px;
  }

  @media (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-left: auto;
    margin-right: auto;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 1;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`;

const Part = styled.h2`
  color: #728695;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0px;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  color: #1c4a6e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin: 0px;
  margin-bottom: 24px;
`;

const Parragraph = styled.p`
  color: #728695;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 28px;
  margin: 0px;
  margin-bottom: ${props => props.marginBottom || "0px"};
`;

export default Part2;
